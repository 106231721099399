import React, {createRef} from "react";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IntlMessages from "../../../util/IntlMessages";
import {connect} from "react-redux";
import {switchBuyDialog, switchSellDialog} from "../../../actions/CurrencyResource";
import '../../../assets/css/formStyle.css'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {currencySymbol, fa2enNumbers, translate} from "../../../util/Utilities";
import "../../../assets/css/main.css"
import {DIGITAL_CURRENCIES, STATUS} from "../../../panel/routes/data/constants";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Popper} from "@material-ui/core";
import {Link} from "react-router-dom";

const ACCEPTED = [STATUS.ACCEPTED_BY_ADMIN, STATUS.ACCEPTED_BY_SYSTEM, STATUS.ACCEPTED_BY_EX_API];

function TabContainer(props) {
    return (
        <div>
            {props.children}
        </div>
    );
}

const PopperMy = function (props) {
    return <Popper {...props} className='select-currency-popover' placement="bottom-start" />;
};

class Calculator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            inputs: {
                conversion: 1,
                origin_currency: 1,
                currency_amount: "",
                selectedBase: DIGITAL_CURRENCIES.IRR,
                total: 0,
                soloPrice: "",
                selectedCoinLogo: "",
                selectedPair: {},
                selectedCurrencyInput: '',
            },
            refs: {
                sellCurrency: createRef(),
            },
            value: 1,
        };

        if(props.currencies && Object.keys(props.currencies).length) {
            this.state.inputs.origin_currency = Object.values(props.currencies).filter((pair) => ACCEPTED.includes(pair.buy_active))[0]['currency']
            this.state.inputs.selectedPair = Object.values(this.props.currencies).filter((pair) => pair.currency == this.state.inputs.origin_currency)[0];
        }
    }



    componentWillReceiveProps(nextProps, nextContext) {
        const inputs = this.state.inputs;
        if(!Object.keys(this.props.currencies).length < 2 && Object.keys(nextProps.currencies).length > 2) {
            inputs.origin_currency = Object.values(nextProps.currencies).filter((pair) => ACCEPTED.includes(pair.buy_active))[0]['currency']
            inputs.selectedPair = Object.values(nextProps.currencies).filter((pair) => pair.currency == inputs.origin_currency)[0];

            this.setState({inputs: inputs});
        }

        // if(nextProps.currency) {
        //     let pairs = Object.values(nextProps.currencies);
        //     const {inputs} = this.state;
        //     if(this.state.conversion === 1) {
        //         if(!ACCEPTED.includes(pairs.filter((pair) => this.state.inputs.origin_currency === pair.currency)[0]['buy_active'])){
        //             inputs.origin_currency = pairs.filter((pair) => ACCEPTED.includes(pair.buy_active))[0]['currency']
        //         }
        //            this.setState({inputs: inputs})
        //     }else {
        //         if(!ACCEPTED.includes(
        //             pairs.filter((pair) => this.state.inputs.origin_currency === pair.currency)[0]['sell_active'])){
        //             inputs.origin_currency = pairs.filter((pair) => ACCEPTED.includes(pair.sell_active))[0]['currency']
        //         }
        //         this.setState({inputs: inputs})
        //     }
        // }

    }

     validateFormValue = (name, value) => {
        value = fa2enNumbers(value);
        let {inputs, widget_back} = this.state;
        inputs[name] = value;
        let currencyPrice;
        if(inputs.conversion === 1) { // BUY
            currencyPrice = this.props.currencies ? this.props.currencies[currencySymbol(this.props.baseCurrencies, inputs.origin_currency) + 'IRR']['buy'] : "";
        }else  { //sell
            currencyPrice = this.props.currencies ? this.props.currencies[currencySymbol(this.props.baseCurrencies, inputs.origin_currency) + 'IRR']['sell'] : "";
        }
        inputs.total = currencyPrice * inputs.currency_amount;
        inputs.soloPrice = currencyPrice * 1;
        if(['destination_currency', 'origin_currency'].includes(name))
            inputs.selectedPair = Object.values(this.props.currencies).filter((pair) => pair.currency == value)[0];
        this.setState({inputs, widget_back});
        return true;
    };

    handleClick = (name, value) => {
        this.validateFormValue(name, value);

    };

    handleChange = (e) => {
        const {name, value} = e.target;
        this.validateFormValue(name, value);

    };

    onChange = (event, value) => {
        this.setState({value});
    };

    getOptions = (currencies) => {
        const options = [];
        Object.values(currencies).filter((pair) => ACCEPTED.includes(pair.buy_active)).map((pair) => (
            options.push({
                label: (
                    <div className="d-inline">
                        <div className="coin-logo-converter mr-1 float-right" style={{ backgroundPositionY: ([pair.currency] - 1) * -20}} />
                        <div className="jr-fs-lg ml-1">
                            {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency))} ({currencySymbol(this.props.baseCurrencies, pair.currency)})
                        </div>
                    </div>
                ),
                value: pair.currency
            })
        ));
        return options
    }

    render() {
        const {inputs} = this.state;
        const {loadingCurrencies, currencies, darkTheme} = this.props;
        const currencyDatasLoaded = !loadingCurrencies && this.props.translates['BTC'] && this.props.baseCurrencies['1'];
        const {value} = this.state;
        return (
            <div className="jr-card calculator-wrapper m-0">
                <div className="calculator-inner-wrapper">
                    <div className="calculator">
                        <Tabs
                            value={value}
                            onChange={this.onChange}
                            variant="fullWidth"
                            scrollButtons="on"
                            className="calculator-tab"
                        >
                            <Tab className="tab" label={"خرید از سایت"} onClick={()=> this.handleClick('conversion', 1)} name= 'conversion'  value={1}/>
                            <Tab className="tab" label={"فروش به سایت"} onClick={()=>this.handleClick('conversion', 2)}  name= 'conversion' value={2} />

                        </Tabs>
                        {!currencyDatasLoaded && (
                            <TabContainer>
                                <fieldset disabled={loadingCurrencies} className="p-0">
                                    <form autoComplete="off" className="row calc-form">
                                        <div className="col-12 mb-3 px-2 calculator-loading">
                                            <CircularProgress/>
                                            <p className="w-100 text-center">در حال بارگذاری</p>
                                        </div>
                                    </form>
                                </fieldset>
                            </TabContainer>
                        )}
                        {currencyDatasLoaded && value === 1 &&
                        <TabContainer>
                            <fieldset disabled={loadingCurrencies} className="p-0">
                                <form autoComplete="off" className="row calc-form">
                                    <div className="col-6 mb-3 px-2">
                                        <FormControl className="w-100 select-currency-wrapper">
                                            <Autocomplete
                                                id="currency-select"
                                                // style={{ width: 300 }}
                                                PopperComponent={PopperMy}
                                                value={inputs.selectedPair}
                                                // defaultValue={inputs.selectedPair}
                                                options={Object.values(currencies).filter((pair) => pair.currency !== DIGITAL_CURRENCIES.WM && ACCEPTED.includes(pair.buy_active))}
                                                // classes='jr-fs-lg'
                                                // open={true}
                                                inputValue={inputs.selectedCurrencyInput}
                                                // defaultValue={inputs.selectedPair}
                                                onOpen={() => {
                                                    this.validateFormValue('selectedCurrencyInput', '');
                                                    }
                                                }
                                                onInputChange={(event, newValue) => {
                                                    this.validateFormValue('selectedCurrencyInput', newValue);
                                                }}
                                                onChange={(event, newValue) => {
                                                    this.validateFormValue('origin_currency', newValue.currency);
                                                }}
                                                autoHighlight
                                                disableClearable
                                                getOptionLabel={(pair) => {
                                                    return translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency)) + " (" + currencySymbol(this.props.baseCurrencies, pair.currency) + ")"
                                                }}
                                                renderOption={(pair) => (
                                                    <React.Fragment>
                                                        <MenuItem className="auto-complete-menu-item" key={pair.currency} value={pair.currency}>
                                                            <div className="coin-name-logo-wrapper">
                                                                <div className="coin-logo-converter" style={{ backgroundPositionY: ([pair.currency] - 1) * -20}} />
                                                                <div className="coin-name">
                                                                    {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency))} ({currencySymbol(this.props.baseCurrencies, pair.currency)})
                                                                </div>
                                                            </div>
                                                        </MenuItem>
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => {
                                                    return (
                                                        <div className="selected-currency">
                                                            <div className="coin-logo-converter mr-1 float-right"
                                                                 style={{backgroundPositionY: ([inputs.selectedPair.currency] - 1) * -22}}/>
                                                            <TextField
                                                                {...params}
                                                                label={<IntlMessages id="title.destination_currency"/>}
                                                                variant="outlined"
                                                                size="small"
                                                                inputProps={{
                                                                    // startAdornment: <div className="coin-logo-converter mr-1 float-right" style={{ backgroundPositionY: ([params.currency] - 1) * -20}} />,
                                                                    ...params.inputProps,
                                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                                }}
                                                            />
                                                        </div>

                                                    )
                                                }}
                                            />
                                            {/*<Select autoComplete={'off'}*/}
                                            {/*        className='jr-fs-lg'*/}
                                            {/*        classNamePrefix="select"*/}
                                            {/*    // defaultValue={colourOptions[0]}*/}
                                            {/*        isDisabled={false}*/}
                                            {/*        isLoading={false}*/}
                                            {/*        isClearable={true}*/}
                                            {/*        isRtl={false}*/}
                                            {/*        isSearchable={true}*/}
                                            {/*        name='origin_currency'*/}
                                            {/*        onChange={(data) => this.validateFormValue('origin_currency', data ? data.value : '')}*/}
                                            {/*        value={inputs.origin_currency}*/}
                                            {/*        options={this.getOptions(currencies)}*/}
                                            {/*/>*/}
                                            {/*<TextField*/}
                                            {/*    className="jr-fs-lg"*/}
                                            {/*    size='small'*/}
                                            {/*    variant="outlined"*/}
                                            {/*    select*/}
                                            {/*    style={{direction: "rtl"}}*/}
                                            {/*    label={<IntlMessages id="title.destination_currency"/>}*/}
                                            {/*    value={inputs.origin_currency}*/}
                                            {/*    onChange={this.handleChange}*/}
                                            {/*    inputProps={{*/}
                                            {/*        name: 'origin_currency',*/}
                                            {/*        id: 'demo-controlled',*/}
                                            {/*    }}>*/}
                                            {/*    {Object.values(currencies).filter((pair) => ACCEPTED.includes(pair.buy_active)).map((pair, index) => (*/}


                                            {/*        <MenuItem className="jr-fs-lg px-1" key={pair.currency} value={pair.currency}*/}
                                            {/*                  selected={index === 0}>*/}
                                            {/*            <div className="d-inline">*/}
                                            {/*                <div className="coin-logo-converter mr-1 float-right" style={{ backgroundPositionY: ([pair.currency] - 1) * -20}} />*/}
                                            {/*                <div className="jr-fs-lg ml-1">*/}
                                            {/*                    {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency))} ({currencySymbol(this.props.baseCurrencies, pair.currency)})*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </MenuItem>*/}


                                            {/*    ))}*/}
                                            {/*</TextField>*/}
                                        </FormControl>
                                    </div>
                                    <div className="col-6 mb-3 px-2">
                                        <FormControl className="w-100">
                                            <TextField
                                                className="jr-fs-lg"
                                                size='small'
                                                variant="outlined"
                                                id="search"
                                                label={<IntlMessages id="title.currency_amount"/>}
                                                onChange={this.handleChange}
                                                value={inputs.currency_amount}
                                                name="currency_amount"
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                                style={{
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-6 px-2 converter-total-row ltr" >
                                        1 {currencySymbol(this.props.baseCurrencies, inputs.selectedPair.currency)} <DragHandleIcon className="equal-icon"/>
                                        {parseInt(inputs.selectedPair.buy / 10).toLocaleString()} <span className="metric" title='تومان'>T</span>

                                    </div>
                                    <div className="col-6 px-2">
                                        <Link to={"./signin"}>
                                        <Button
                                            variant="contained"
                                            className="calc-buy-btn"
                                            disabled={loadingCurrencies}
                                            fullWidth
                                            // onClick={() => this.props.switchBuyDialog({state: true, selectedCurrency: inputs.origin_currency, initialAmount: inputs.currency_amount, selectedBase: inputs.selectedBase})}
                                        >
                                            <IntlMessages id="title.start-buying" />
                                        </Button>
                                        </Link>
                                    </div>
                                    <div className="coin-info-wrapper">
                                        <div className=" coin-change-wrapper">
                                            {/*{Object.values(currencies).filter((pair) => ACCEPTED.includes(pair.buy_active) && pair.currency === inputs.origin_currency).map((pair, index) => (*/}

                                            {/*    <span>{pair.change}</span>*/}


                                            {/*))}*/}
                                            <span className="coin-changes">{inputs.selectedPair.change < 0 ? <RemoveIcon className="remove-icon"/> : <AddIcon className="add-icon"/>}{Math.abs(inputs.selectedPair.change)}%</span>
                                            <img alt="alt" className={inputs.selectedPair.change >= 0 ? "success-change-v coin-changes-v" : "coin-changes-v"} src={darkTheme ? require("../../../assets/images/common/whiteV.svg") : require("../../../assets/images/common/blackV.svg")}/>
                                        </div>
                                        <div className=" logo-wrapper">
                                            <div className="logo mr-1 float-right"
                                                 style={Object.keys(inputs.selectedPair).length === 0 ? {backgroundPositionY: ([1] - 1) * -64} : { backgroundPositionY: ([inputs.selectedPair.currency] - 1) * -64}}/>

                                            <div className="price-wrapper">
                                            <span className='calculator-total-title'>
                                                پرداخت می کنید:
                                            </span>
                                                <span className='calculator-total'>
                                                 {parseInt(inputs.selectedPair.buy * inputs.currency_amount / 10).toLocaleString()} تومان
                                            </span>
                                            </div>

                                        </div>
                                    </div>

                                </form>
                            </fieldset>
                        </TabContainer>}
                        {currencyDatasLoaded && value === 2 &&
                        <TabContainer>
                            <fieldset disabled={loadingCurrencies} className="p-0">
                                <form  className="row calc-form" >
                                    <div className="col-6 mb-3 px-2">
                                        <FormControl className="w-100 select-currency-wrapper" >
                                            <Autocomplete
                                                id="currency-select"
                                                // style={{ width: 300 }}
                                                PopperComponent={PopperMy}
                                                value={inputs.selectedPair}
                                                inputValue={inputs.selectedCurrencyInput}

                                                // defaultValue={inputs.selectedPair}
                                                onOpen={() => {
                                                    this.validateFormValue('selectedCurrencyInput', '');
                                                }
                                                }
                                                options={Object.values(currencies).filter((pair) => pair.currency !== DIGITAL_CURRENCIES.WM && ACCEPTED.includes(pair.sell_active))}
                                                // classes='jr-fs-lg'
                                                // open={true}

                                                onChange={(event, newValue) => {
                                                    this.validateFormValue('origin_currency', newValue.currency);
                                                }}
                                                onInputChange={(event, newValue) => {
                                                    this.validateFormValue('selectedCurrencyInput', newValue);
                                                }}
                                                autoHighlight
                                                disableClearable
                                                getOptionLabel={(pair) => {
                                                    return translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency)) + " (" + currencySymbol(this.props.baseCurrencies, pair.currency) + ")"
                                                }}
                                                renderOption={(pair) => (
                                                    <React.Fragment>
                                                        <MenuItem className="auto-complete-menu-item" key={pair.currency} value={pair.currency}>
                                                            <div className="coin-name-logo-wrapper">
                                                                <div className="coin-logo-converter" style={{ backgroundPositionY: ([pair.currency] - 1) * -20}} />
                                                                <div className="coin-name">
                                                                    {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency))} ({currencySymbol(this.props.baseCurrencies, pair.currency)})
                                                                </div>
                                                            </div>
                                                        </MenuItem>
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => {
                                                    return (
                                                        <div className="selected-currency">
                                                            <div className="coin-logo-converter mr-1 float-right"
                                                                 style={{backgroundPositionY: ([inputs.selectedPair.currency] - 1) * -22}}/>
                                                            <TextField
                                                                {...params}
                                                                inputRef={this.state.refs.sellCurrency}
                                                                label={<IntlMessages id="title.destination_currency"/>}
                                                                variant="outlined"
                                                                size="small"
                                                                inputProps={{
                                                                    // startAdornment: <div className="coin-logo-converter mr-1 float-right" style={{ backgroundPositionY: ([params.currency] - 1) * -20}} />,
                                                                    ...params.inputProps,
                                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                                }}
                                                            />
                                                        </div>

                                                    )
                                                }}
                                            />
                                            {/*<TextField*/}
                                            {/*    className="jr-fs-lg"*/}
                                            {/*    size='small'*/}
                                            {/*    style={{direction: "rtl"}}*/}
                                            {/*    variant="outlined"*/}
                                            {/*    select*/}
                                            {/*    label={<IntlMessages id="title.origin_currency"/>}*/}
                                            {/*    value={inputs.origin_currency}*/}
                                            {/*    name='origin_currency'*/}
                                            {/*    onChange={this.handleChange}*/}
                                            {/*    inputProps={{*/}
                                            {/*        name: 'origin_currency',*/}
                                            {/*        id: 'demo-controlled',*/}
                                            {/*    }}>*/}
                                            {/*    {Object.values(currencies).filter((pair) => ACCEPTED.includes(pair.sell_active)).map((pair, index) => (*/}


                                            {/*        <MenuItem className="jr-fs-lg px-1" key={pair.currency} value={pair.currency}*/}
                                            {/*                  selected={index === 0}>*/}
                                            {/*            <div className="d-inline">*/}
                                            {/*                <div className="coin-logo-converter mr-1 float-right" style={{ backgroundPositionY: ([pair.currency] - 1) * -20}} />*/}
                                            {/*                <div className="jr-fs-lg ml-1">*/}
                                            {/*                    {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency))} ({currencySymbol(this.props.baseCurrencies, pair.currency)})*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </MenuItem>*/}


                                            {/*    ))}*/}
                                            {/*</TextField>*/}
                                        </FormControl>
                                    </div>
                                    <div className="col-6 mb-3 px-2">
                                        <FormControl className="w-100">
                                            <TextField
                                                className="jr-fs-lg"
                                                size='small'
                                                variant="outlined"
                                                id="search"
                                                label={<IntlMessages id="title.currency_amount"/>}
                                                onChange={this.handleChange}
                                                value={inputs.currency_amount}
                                                name="currency_amount"
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                                style={{
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="col-6 px-2 converter-total-row ltr" >
                                        1 {currencySymbol(this.props.baseCurrencies, inputs.selectedPair.currency)} <DragHandleIcon className="equal-icon"/>
                                        {parseInt(inputs.selectedPair.sell / 10).toLocaleString()} <span className="metric" title='تومان'>T</span>

                                    </div>
                                    <div className="col-6 px-2">
                                        <Link to={"./signin"}>
                                            <Button
                                                variant="contained"
                                                className="calc-sell-btn"
                                                disabled={loadingCurrencies}
                                                fullWidth
                                                // onClick={() => this.props.switchSellDialog({state: true, selectedCurrency: inputs.origin_currency, initialAmount: inputs.currency_amount, selectedBase: inputs.selectedBase})}
                                            >
                                                <IntlMessages id="title.start-selling" />
                                            </Button>
                                        </Link>

                                    </div>
                                    <div className="coin-info-wrapper">
                                    <div className=" coin-change-wrapper">
                                        <span className="coin-changes">{inputs.selectedPair.change < 0 ? <RemoveIcon className="remove-icon"/> : <AddIcon className="add-icon"/>}{Math.abs(inputs.selectedPair.change)}%</span>
                                        <img alt="alt" className={inputs.selectedPair.change >= 0 ? "success-change-v coin-changes-v" : "coin-changes-v"} src={darkTheme ? require("../../../assets/images/common/whiteV.svg") : require("../../../assets/images/common/blackV.svg")}/>
                                    </div>
                                    <div className="logo-wrapper">
                                        <div className="logo mr-1 float-right" style={Object.keys(inputs.selectedPair).length === 0 ? {backgroundPositionY: ([1] - 1) * -64} : { backgroundPositionY: ([inputs.selectedPair.currency] - 1) * -64}} />
                                        <div className="price-wrapper">
                                            <span className='calculator-total-title'>
                                                دریافت می کنید:
                                            </span>
                                            <span className='calculator-total'>
                                                 {parseInt(inputs.selectedPair.sell * inputs.currency_amount / 10).toLocaleString()} تومان
                                            </span>
                                        </div>

                                    </div>
                                    </div>
                                </form>
                            </fieldset>
                        </TabContainer>}
                    </div>

                </div>
            </div>



        );
    }
};

const mapStateToProps = ({currency, settings}) => {
    const {currencies, loadingCurrencies, baseCurrencies, translates} = currency;
    const {darkTheme} = settings
    return {currencies, loadingCurrencies, baseCurrencies, translates, darkTheme}
};
export default connect(mapStateToProps, {switchBuyDialog, switchSellDialog})(Calculator);
